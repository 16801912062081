/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import * as React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';

import { parallaxEffect } from '../utils/styleUtils';
import theme from '../theme';
import InputField from './InputField';
import Text from './Text';
import Section from './Section';
import AnimatedContainer from './AnimatedContainer';

const STATE = {
  NOT_SENT: 'NOT_SENT',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const encode = (data: Record<string, string>) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

const schema = yup.object({
  firstName: yup.string()
    .max(50, 'Maximal 50 Zeichen')
    .required('Benötigt'),
  lastName: yup.string()
    .max(50, 'Maximal 50 Zeichen')
    .required('Benötigt'),
  email: yup.string()
    .email('Ungültige Email-Adresse')
    .max(50, 'Maximal 50 Zeichen')
    .required('Benötigt'),
  message: yup.string()
    .max(2000, 'Maximal 2000 Zeichen')
    .required('Benötigt'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

const containerStyle = css`
    flex: 1;
    display: flex;
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
`;

const formStyle = () => css`
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    align-items: stretch;
    padding: 30px;
    ${theme.breakpoints.up('md')} {
      transform-origin: 0 center 0;
      ${parallaxEffect(-0.4)};
      margin-top: 180px;
    }
`;

const inputStyle = css`
    margin-top: 8px;
`;

const buttonStyle = css`
    border: 2px solid #555555;
    background-color: transparent;
    outline: none;
    font-weight: bold;
    font-family: Niveau Grotesk, sans-serif;
    font-size: 1.2em;
    padding: 8px;
    margin-top: 32px;
    cursor: pointer;
`;

const feedbackStyle = css`
    margin: 16px;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
`;

const textStyle = css`
  padding: 0 30px;
  flex-basis: 50%;
  ${theme.breakpoints.up('md')} {
    text-align: right;
  }
`;

function ContactForm(): React.ReactElement {
  const [state, setState] = React.useState(STATE.NOT_SENT);
  const ref = React.useRef(null);

  const handleReset = React.useCallback(() => setState(STATE.NOT_SENT), []);

  const handleSubmit = React.useCallback(async (values) => {
    try {
      const result = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...values }),
      });
      if (result.ok) {
        setState(STATE.SUCCESS);
      } else {
        setState(STATE.ERROR);
      }
    } catch (e) {
      setState(STATE.ERROR);
    }
  }, []);

  const renderBody = React.useCallback((formikProps: FormikProps<typeof initialValues>) => {
    const { isSubmitting } = formikProps;

    function renderForm() {
      if (state === STATE.SUCCESS) {
        return (
          <div css={feedbackStyle}>Vielen Dank für deine Nachricht! Wir werden uns in Kürze bei dir melden.</div>
        );
      }
      if (state === STATE.ERROR) {
        return (
          <div css={feedbackStyle}>
            Tut uns Leid, etwas ist schief gelaufen.
            <button type="button" onClick={handleReset} css={buttonStyle}>Nochmals versuchen</button>
          </div>
        );
      }
      return (
        <AnimatedContainer ComponentType={Form} css={formStyle} direction="right">
          <InputField styles={inputStyle} name="firstName" placeholder="Vorname" />
          <InputField styles={inputStyle} name="lastName" placeholder="Nachname" />
          <InputField styles={inputStyle} name="email" placeholder="Email" />
          <InputField styles={inputStyle} name="message" placeholder="Nachricht" multiline />
          <button css={buttonStyle} type="submit" disabled={isSubmitting}>
            Absenden
          </button>
        </AnimatedContainer>
      );
    }

    return (
      <Section id="contact" ref={ref} additionalTopPadding={10}>
        <div css={containerStyle}>
          <AnimatedContainer direction="left" css={textStyle}>
            <Text variant="h2">Kontakt</Text>
            <p>
              Falls du uns für einen Anlass anfragen möchtest, mehr Informationen benötigst oder Feedback geben willst,
              dann fülle bitte das Kontaktformular aus oder schreib uns eine Mail an
              {' '}
              <a target="_blank" rel="noreferrer" href="mailto:info@gingerella-music.ch">info@gingerella-music.ch</a>
              . Wir freuen uns von dir zu hören!
            </p>
          </AnimatedContainer>
          {renderForm()}
        </div>
      </Section>
    );
  }, [state, handleReset]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {renderBody}
    </Formik>
  );
}

export default ContactForm;
