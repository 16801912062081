/** @jsx jsx */
import { jsx, css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { Field, FieldProps } from 'formik';

const containerStyle = css`
    display: flex;
    flex-direction: column;
`;

const inputStyle = (error: boolean, multiline: boolean) => css`
    border: none;
    border-bottom: 2px solid ${error ? '#B53737' : '#555555'};
    height: ${multiline ? 80 : 40}px;
    font-size: 1.2em;
    padding: 5px 0px;
    font-family: Niveau Grotesk, sans-serif;
    ::placeholder {
        ${error ? 'color: #B53737;' : ''}
    }
    &:focus {
        outline: none;
    }
`;

const errorStyle = css`
    color: #B53737;
    font-size: 0.8em;
`;

type Props = {
  name: string
  placeholder: string
  styles: SerializedStyles
  multiline?: boolean
};

const defaultProps: Partial<Props> = {
  multiline: false,
};

function InputField(props: Props): React.ReactElement {
  const {
    name, placeholder, multiline = false, styles,
  } = props;

  const renderField = React.useCallback((formikBag: FieldProps<string>) => {
    const { field, meta } = formikBag;
    const isError = meta.touched && !!meta.error;
    const Component = multiline ? 'textarea' : 'input';
    return (
      <div css={css`${containerStyle};${styles};`}>
        <Component
          css={inputStyle(isError, multiline)}
          type="text"
          placeholder={placeholder}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
        />
        {meta.touched && meta.error && (
        <div css={errorStyle}>{meta.error}</div>
        )}
      </div>
    );
  }, [multiline, placeholder, styles]);

  return (
    <Field name={name}>
      {renderField}
    </Field>
  );
}

InputField.defaultProps = defaultProps;

export default InputField;
