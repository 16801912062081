/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import theme from '../theme';

const separatorStyle = css`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 0.8px;
    background-color: ${theme.palette.grey[500]};
    ${theme.breakpoints.down('md')} {
      display: none;
    }
`;

function VerticalSeparator(): React.ReactElement {
  return <div css={separatorStyle} />;
}

export default VerticalSeparator;
