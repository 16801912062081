/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import theme from '../theme';
import Text from './Text';
import Section from './Section';
import AnimatedContainer from './AnimatedContainer';

const textStyle = (align: 'left' | 'right') => css`
  padding: 0 30px;
  ${theme.breakpoints.up('md')} {
    flex-basis: 50%;
    text-align: ${align === 'left' ? 'right' : 'left'};
  }
`;

const imageContainerStyle = (align: 'left' | 'right') => css`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 30px;
  }
  ${theme.breakpoints.up('md')} {
    display: flex;
    justify-content: flex-${align === 'left' ? 'end' : 'start'};
    align-items: center;
    flex-basis: 50%;
    height: 350px;
    transform-origin: ${align === 'left' ? '100' : 0}% center 0;
  }
`;

const imageStyle = css`
  ${theme.breakpoints.up('md')} {
    flex: 1;
    height: 100%;
    max-width: 250px;
    max-height: 350px;
  }
`;

type Props = {
  teaserId: string
  link: string
  linkLabel: string
  image: IGatsbyImageData
  description: string
  header: string
  align: 'left' | 'right'
};

function Teaser(props: Props): React.ReactElement {
  const {
    image, teaserId, link, linkLabel, description, header, align,
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  function renderContent(part: 'left' | 'right') {
    if ((part === 'left' && align === 'right') || (part === 'right' && align === 'left')) {
      return (
        <AnimatedContainer direction={part} css={imageContainerStyle(part)}>
          <GatsbyImage alt={teaserId} image={image} css={imageStyle} />
        </AnimatedContainer>
      );
    }
    return (
      <AnimatedContainer direction={part} css={textStyle(part)}>
        <Text variant="h2">{header}</Text>
        <Text>
          {description}
        </Text>
        <Link to={link}>{linkLabel}</Link>
      </AnimatedContainer>
    );
  }

  return (
    <Section id={teaserId} ref={ref} additionalTopPadding={10} reverseForMobile={align === 'right'}>
      {renderContent('left')}
      {renderContent('right')}
    </Section>
  );
}

export default Teaser;
