import { UAParser } from 'ua-parser-js';

import theme from '../theme';

export const isIos13 = (): boolean => {
  const parser = new UAParser();
  const os = parser.getOS();
  return os.name === 'iOS' && os.version != null && os.version.startsWith('13');
};

export const ifCssParallax = (styles: string): string => (isIos13() ? '' : styles);

export const parallaxEffect = (value: number): string => (
  ifCssParallax(`transform: scale(${1 - value / theme.parallax.perspective}) translateZ(${value}px);`)
);
