/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Gingerella from './Gingerella';
import theme from '../theme';

const rootStyle = css`
  position: relative;
`;

const imageStyle = css`
  position: relative;
  height: 100vh;
  width: 100vw;
  ${theme.breakpoints.up('md')} {
    height: calc(100vh - 40px);
  }
`;

type Props = {
  image: IGatsbyImageData
};

function FrontImage(props: Props): React.ReactElement {
  const { image } = props;

  return (
    <div css={rootStyle}>
      <GatsbyImage alt="front" image={image} css={imageStyle} />
      <Gingerella />
    </div>
  );
}

export default FrontImage;
