/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { withPrefix } from 'gatsby';

import theme from '../theme';

const SCALING_FACTOR = 0.11;
const scaledValue = (value: number) => `min(${value}px,${SCALING_FACTOR * value}vw)`;

const containerStyle = css`
    pointer-events: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 30%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    fill: green;
    .gingerella {
      fill: green;
    }
`;

const subtitleStyle = css`
    ${theme.typography.subtitle};
    font-size: ${scaledValue(30)};
    margin-bottom: calc(-1 * ${scaledValue(20)});
    user-select: none;
`;

const gingerellaStyle = css`
    height: ${scaledValue(160)};
`;

function Gingerella(): React.ReactElement {
  return (
    <div css={containerStyle}>
      <span css={subtitleStyle}>Andrea & Timon</span>
      <img css={gingerellaStyle} src={`${withPrefix('/')}img/gingerella.svg`} alt="gingerella" />
    </div>
  );
}

export default Gingerella;
