/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import theme from '../theme';

const containerStyle = (additionalTopPadding: number, reverseForMobile: boolean) => css`
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    ${theme.breakpoints.up('md')} {
      max-width: 800px;
      min-height: 100vh;
    }
    ${theme.breakpoints.down('md')} {
      padding-top: calc(25px + ${additionalTopPadding}vh);
      padding-bottom: 25px;
      flex-direction: column${reverseForMobile ? '-reverse' : ''};
    }
`;

type Props = {
  children: React.ReactNode,
  additionalTopPadding?: number,
  reverseForMobile?: boolean,
  className?: string,
  id?: string,
};

const Section = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    id, children, className, additionalTopPadding = 0, reverseForMobile = true,
  } = props;
  return (
    <div ref={ref} id={id} className={className} css={containerStyle(additionalTopPadding, reverseForMobile)}>
      {children}
    </div>
  );
});

Section.defaultProps = {
  additionalTopPadding: 0,
};

export default Section;
