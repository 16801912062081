/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useInView } from 'react-intersection-observer';
import theme from '../theme';

const TRANSITION_TIME = 0.8;

const containerStyle = css`
  position: relative;
  transition: opacity ${TRANSITION_TIME}s ease-in;
  opacity: 0;
`;
const containerEnteredStyle = css`
  opacity: 1;
`;

const coverStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: transform ${TRANSITION_TIME}s ease-in;
  background-color: white;
  ${theme.breakpoints.down('md')} {
    display: none;
  }
`;

const uncoveredStyle = (direction: 'left' | 'right') => css`
   transform: translateX(${direction === 'left' ? '-' : ''}50vw);
`;

type Props = {
  children: React.ReactNode
  direction: 'left' | 'right'
  className?: string
  ComponentType: React.ElementType
};

function AnimatedContainer(props: Props): React.ReactElement | null {
  const {
    children, ComponentType, direction, className,
  } = props;
  const [ref, inView] = useInView({ threshold: 0, delay: 1, triggerOnce: true });

  return (
    <ComponentType
      css={[containerStyle, inView && containerEnteredStyle]}
      className={className}
      ref={ref}
    >
      {children}
      <div css={[coverStyle, inView && uncoveredStyle(direction)]} />
    </ComponentType>
  );
}

AnimatedContainer.defaultProps = {
  className: undefined,
  ComponentType: 'div',
} as Partial<Props>;

export default AnimatedContainer;
