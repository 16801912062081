/** @jsx jsx */
import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { jsx, css } from '@emotion/react';
import {
  getImage, ImageDataLike, IGatsbyImageData,
} from 'gatsby-plugin-image';

import Teaser from '../components/Teaser';
import FrontImage from '../components/FrontImage';
import MainContainer from '../components/MainContainer';
import ContactForm from '../components/ContactForm';
import VerticalSeparator from '../components/VerticalSeparator';

const rootStyle = css`
  position: relative;
`;

type TeaserType = {
  title: string,
  image: {
    localFile: ImageDataLike
  },
  link: {
    url: string
  }
  link_uri: string
  link_label: string
  description: {
    text: string
  }
};

type Props = {
  frontImage: ImageDataLike
  teasers: TeaserType[]
};

export function IndexPageTemplate(props: Props): React.ReactElement {
  const { frontImage, teasers } = props;

  function renderTeaser(teaser: TeaserType, idx: number) {
    return (
      <Teaser
        image={getImage(teaser.image.localFile) as IGatsbyImageData}
        link={teaser.link_uri}
        teaserId={teaser.link_uri}
        header={teaser.title}
        description={teaser.description.text}
        linkLabel={teaser.link_label}
        align={idx % 2 === 0 ? 'right' : 'left'}
      />
    );
  }

  return (
    <MainContainer>
      <div css={rootStyle}>
        <VerticalSeparator />
        <FrontImage image={getImage(frontImage) as IGatsbyImageData} />
        {teasers.map(renderTeaser)}
        <ContactForm />
      </div>
    </MainContainer>
  );
}

type IndexQueryData = {
  prismicHome: {
    data: {
      title_image: {
        localFile: ImageDataLike,
      }
      body: Array<{ primary: TeaserType }>
    }
  }
};

function IndexPage(props: PageProps<IndexQueryData>): React.ReactElement {
  const { data } = props;
  const homeData = data.prismicHome.data;

  return (
    <IndexPageTemplate
      frontImage={homeData.title_image.localFile}
      teasers={homeData.body.map((s) => s.primary)}
    />
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query HomeQuery {
    prismicHome {
      data {
        title_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2048
                placeholder: BLURRED
              )
            }
          }
        }
        body {
          ... on PrismicHomeDataBodyTeaser {
            slice_type
            primary {
              title,
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1024
                      placeholder: BLURRED
                    )
                  }
                }
              },
              link {
                url
              }
              link_uri,
              link_label,
              description {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`;
